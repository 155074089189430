<!-- Le template -->
<template>
  <v-container :key="componentKey">
    <v-row>
      <v-col class="d-flex justify-end pb-5 align-center">
        <!--Switch K€ -->
        <v-switch class="mr-3 my-0 align-center" dense label="K€" v-model="kilo_euro"></v-switch>
        <!--Export image -->
        <v-btn @click="exportImage('compte-de-resultat')" color="grey darken-3" class="mr-1">
          <v-icon dark left>mdi-file-image</v-icon>image
        </v-btn>
        <!--Export excel -->
        <export-excel v-if="!loading" :data="crs" name="cr.xls" :fields="set_excelHeaders() ">
          <v-btn color="grey darken-3 ">
            <v-icon dark left>mdi-file-export-outline</v-icon>
            Excel
          </v-btn>
        </export-excel>
      </v-col>

    </v-row>

    <v-progress-linear v-if="loading" color="light-blue darken-1" indeterminate rounded
      height="6"></v-progress-linear>

    <!-- CR Visible Table -->
    <v-simple-table v-if="!loading" fixed-header dark dense id="crtable" :key="resultatDatatableKey">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="padding: 5px">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-checkbox v-model="selectall_cr" @click="toggleAllRowVisibility(selectall_cr, crs)" v-on="on"
                    true-value="1" false-value="0" dense hide-details color="grey lighten-5"></v-checkbox>
                </template>
                <span>Tout Masquer (pour l'exportation en image)</span>
              </v-tooltip>
            </th>
            <th class="text-left">Intitulé</th>
            <th v-for="(annee,index) in years" class="text-right border-left" :key="'tabannee'+index">
              {{ annee }}<br><span v-if="kilo_euro">K</span>€
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cr,crindex) in crs" :key="crindex">
            <td class="checkboxTD">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-checkbox v-model="crs[crindex].show" :input-value="crs[crindex].show" @click="
                toggleRowVisibility(
                  crs[crindex].show,
                  crs,
                  crindex
                )
              " true-value="1" false-value="0" v-on="on" dense hide-details color="grey lighten-5"></v-checkbox>
                </template>
                <span>Masquer cette ligne pour l'exportation en image</span>
              </v-tooltip>
            </td>
            <td v-if="cr.niv==4">&nbsp;&nbsp;{{cr.intitule}}</td>
            <td v-else>{{cr.intitule}}</td>
            <template v-for="(annee,index) in years">
              <td :key="'montant'+index" class="text-right value border-left">
                {{ format(cr['montant_'+annee], cr) }}
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- CR Export Image Table -->
    <div style="position: absolute; z-index: -1">
      <v-simple-table id="compte-de-resultat" v-if="!loading" light dense class="pb-3 imgtable" style="overflow-x: auto !important" :key="exportResultatDatatableKey">
        <template v-slot:default>
          <thead style="overflow-x: auto !important">
            <tr style="background-color: #0c6682">
              <th class="text-left" style="color: #ffffff !important">
                Intitulé
              </th>
              <th v-for="(annee,index) in years" :key="'imagetable'+index" class="text-right border-left"
                style="color: #ffffff !important" >
                {{ annee }}<br><span v-if="kilo_euro">K</span>€
              </th>
            </tr>
          </thead>
          <tbody style="overflow-x: auto !important">
            <template v-for="(cr, crindex) in crs">
              <tr v-if="crs[crindex].show == '1'" :key="'intitule'+crindex" :class="`niv` + cr.niv">
                <td class='intitule'>
                  {{cr.intitule}}                                   
                </td>
                <template v-for="(annee,index) in years">
                  <td :key="'imagetablemontant'+index" class="text-right value border-left">
                    {{ format(cr['montant_'+annee], cr) }}
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-container>
</template>
<style>
.imgtable td,
.imgtable th {
  height: auto !important;
  line-height: 1.3 !important;
}

/* 
alternance gris / blanc pour l'export en image des tableaux
tout en blanc cf recette 14/12

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
*/

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}

.niv1 {
  background-color: #1ab7ea !important;
}
.niv1 td {
  padding: 15px !important;
}

.niv2 {
  background-color: #a6a6a6 !important;
}
.niv4 td {  
  font-size: 0.9em !important;
}
.niv4 .intitule {padding-left:30px !important;}



.checkboxTD {
  width: 10px !important;
  padding: 5px 0 5px 5px !important;
}

.br_header {
  width: 220px;
}
.euro_header {
  width: 100px;
}

.border-left {
  border-left: 1px solid gray;
}
</style>

<script>
import { EventBus } from "../../EventBus";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// Le script
export default {
  props: {
    selectedClient: Object,
    annee: Number,
    api_endpoint_url: String
  },
  data() {
    return {
     
      resultatDatatableKey: 0,
      exportResultatDatatableKey: 0,
      componentKey: 0,
      loading: true,
      kilo_euro: false,
      

      crs: [], // compte de résultat par année

      selectall_cr: "1",

    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    years(){ // prendra les valeurs des 5 dernière annee dispo si annee = null
      
      if(this.annee != null ){ 
        return [this.annee]
      }
      else{
      const last5Years = Object.keys(this.crs[0])
          .filter(key => key.startsWith('montant_'))
          .map(key => key.split('_')[1]);

        return last5Years
      }

    }, 
  },

  watch: {
    kilo_euro(val) {
      EventBus.$emit("kilo_euroChanged", val);
    },

  },

  methods: {
    refresh() {
      //this.$forceUpdate()
      this.resultatDatatableKey++;
      this.exportResultatDatatableKey++;

    },

    // toggleAllRowVisibility pour afficher masquer toutes les lignes
    // dans l'exporation image d'un tableau

    toggleAllRowVisibility(state, targetData) {
      
      _.map(targetData, function (obj) {
        obj.show = state;
      });
      this.refresh();
    },


    toggleRowVisibility(state, target, index) {

      let i = index;
      target[index].show = state;
      
      this.refresh();
    },

    exportImage(tab) {
      var node = document.getElementById(tab);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = tab + ".png";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },

    set_excelHeaders() {
      var headers = { intitule: "intitule" };
      this.years.forEach((year) => {
        headers['année '+year] = "montant_" + year;
      });
      return headers;
    },

    /*
     * format value in K€
     */
    format(value, item) {

      // formatage en K€ on préfère que les montants soient arrondis à l’unité cf recette du 31/03/2023 et mail du 12/04
      return this.kilo_euro == true
        ? parseFloat(value / 1000).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : parseFloat(value).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    },


    /*
     * Compte de resultat
     */

    get_crs() {
      this.loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annee: this.annee,
      };

      return new Promise((resolve, reject) => {
        axios
          .get(this.api_endpoint_url, payload)
          .then((response) => {

            // transformation de la reponse
            // [{intitule, annee, montant}] => [{intitule, montant_annee1,montant_annee2,montant_annee3,...}]

            // First, we need to group by both 'intitule' and 'order'
            const grouped = _.groupBy(response.data, item => `${item.intitule}_${item.order}`);

            const transformed = _.map(grouped, (items, key) => {
              // Extract 'intitule' and 'order' from the group key
              const [intitule, order] = key.split('_');
              const obj = { intitule: intitule, order: parseInt(order) };
                              
              // Populate the object with montant_annee properties
              items.forEach(item => {
              obj[`montant_${item.annee}`] = item.montant;
              });

               return obj;
            });              
                       
            // ajout de la propriete show et niveau d'entete
            // niv1 : fond bleu
            // niv2 : fond gris
            // niv3 : default
            // niv4 : legère indentation

            this.crs = _.orderBy(transformed, ["order"], ["asc"]);
            _.map(this.crs, function (obj) {
              obj.show = "1";
              obj.niv = "3";

              //niv 1 pour les orders suivants
              const niv1 =  [60,68,78,82]
              //niv 2 pour les orders suivants               
              const niv2 =  [1,15,61,64,69,74]
              //niv 4 pour les orders suivants  
              const niv4 =  [11,12,13,17,18,19,20,21,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,56,57,58,59] 

              if(niv1.includes(obj.order)){
                obj.niv = "1";
              }
              if(niv2.includes(obj.order)){
                obj.niv = "2";
              }
              if(niv4.includes(obj.order)){
                obj.niv = "4";
              }

            });

            this.loading = false;
            resolve();
          })
          .catch((err) => {
            this.loading = false;

            reject(err);
          });
      });
    },


  },

  mounted() {

    this.get_crs();
    EventBus.$on("cruploaded", (data) => {
      this.get_crs();
    });
  },
};
</script>